
import styles from './about.module.css'

export default function About() {
  return (
    <div className={styles.container}>
        <h1>Page Under Construction</h1>
    </div>
  )
}
