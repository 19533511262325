
import styles from './past-winners.module.css'

export default function PastWinners() {
  return (
    <div className={styles.container}>
        <h1>Page Under Construction</h1>
    </div>
  )
}
